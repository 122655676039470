import React from "react";
import { styled } from "styled-components";
import { sizes } from "./constants/devices";

const PBlockMenuContainer = styled.nav.attrs({
  className: "regenerateMenu",
})``;
const MenuHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const MenuHeader = styled.div`
  padding: 0.6rem 1.1rem;
  font-family: "Noto Sans";
  font-weight: 400;
  font-size: 20px;
  color: white;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 14px;
  }
`;

const MenuItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5.4rem;
`;
const MenuItemWrapperGroup = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: no-wrap;
  gap: 5.4rem;
  @media only screen and (max-width: 950px) {
    max-width: 696px;
  }
  @media only screen and (max-width: 768px) {
    gap: 50px;
  }
  @media only screen and (max-width: 480px) {
    gap: 16px;
  }
`;

const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  width: 134px;
  @media only screen and (max-width: 768px) {
    width: 80px;
  }
`;

const Icon = styled.img`
  max-height: 100px;
  max-width: 100px;
  background-image: url(${(props) => props.$backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    max-height: 60px;
    max-width: 60px;
  }
  @media only screen and (max-width: 480px) {
    max-height: 50px;
    max-width: 50px;
  }
`;

const Label = styled.p`
  font-size: 1.6rem;
  margin: 0;
  color: white;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 10px;
  }
  ${(props) => props.$isActive && "border-bottom: 1px solid white"};
`;

const PBlockMenu = ({
  selectedPlayer,
  handlePlayerChange,
  handleBackgroundChange,
}) => {
  let options = [
    {
      label: "FUTURISTIC",
      icon: "images/menu-images/symbol.png",
    },
    {
      label: "DOG MODE",
      icon: "images/menu-images/dog.png",
    },
    {
      label: "SHAKESPEARINATOR",
      icon: "images/menu-images/quill.png",
    },
    {
      label: "HEAVY METAL-IC",
      icon: "images/menu-images/guitar.png",
    },
    {
      label: "GEN-Z-IFY",
      icon: "images/menu-images/sphere.png",
    },
  ];

  const menuItems = options.map((option, index) => {
    return (
      <MenuItem
        key={index}
        onClick={() => {
          if (selectedPlayer !== index) {
            handleBackgroundChange();
            handlePlayerChange(index, true);
          }
        }}
      >
        <Icon src={option.icon} />
        <Label $isActive={selectedPlayer === index}>{option.label}</Label>
      </MenuItem>
    );
  });

  return (
    <PBlockMenuContainer>
      <MenuHeaderWrapper>
        <MenuHeader>Regenerate Scene:</MenuHeader>
      </MenuHeaderWrapper>
      <MenuItemWrapper>
        <MenuItemWrapperGroup>{menuItems.slice(0, 3)}</MenuItemWrapperGroup>
        <MenuItemWrapperGroup>{menuItems.slice(3)}</MenuItemWrapperGroup>
      </MenuItemWrapper>
    </PBlockMenuContainer>
  );
};

export default PBlockMenu;

import React, { useEffect, useState, useRef } from "react";
import MainMenu from "../components/MainMenu/ModuleMainMenu";
import Footer from "../components/Footer/FooterV2";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import styled from "styled-components";
import Demo from "../components/Demo";
import "./StylePageNaming.css";

import "./articles.css";

/// FRAMER MOTION ///
import { motion, useScroll, useTransform } from "framer-motion";
import { useMediaQuery } from "@react-hook/media-query";

const HeroNaming = styled.div.attrs({
	className: "heroNaming",
})`
	background-image: url(${process.env.PUBLIC_URL}/images/pages/sxsw/sxswbanner_comp.jpg);
	background-size: cover;
	background-position: center;
	position: relative;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(255, 255, 255, 0.6);
		z-index: 1;
	}

	.contentWrapper {
		position: relative;
		z-index: 2;
	}
`;

const SXSWPlaceholder = () => {
  gsap.registerPlugin(useGSAP);
  gsap.registerPlugin(ScrollTrigger);

  const lSections = useRef([]);
  const rSections = useRef([]);

  const pushLRef = (el) => lSections.current.push(el);
  const pushRRef = (el) => rSections.current.push(el);

	return (
		<div className="main-wrapper">
			<MainMenu isArticle={true} />
			<div className="namingWrapper">
				<HeroNaming>
					<div className="contentWrapper" style={{ marginTop: "350px" }}>
						<h1 className="pageTitle">
							Taking PLAY to<br />SXSW 2025
						</h1>
					</div>
					<div className="contentWrapper breadCrumbs" style={{ marginBottom: "20px" }}>
						<div className="breadCrumb">
							<span>sxsw + ai</span>
						</div>
						<div className="hastag">#Consumers #Research #AI</div>
					</div>
				</HeroNaming>
				<div className="contentWrapper">
					<div
						className="textContent"
						style={{
							maxWidth: "640px",
							margin: "60px auto 0",
							textAlign: "left",
							padding: "32px 20px 0"
						}}
					>
						<div className="namingTitle40px">
            Humans versus AI: Who will reign supreme? 
						</div>
            <div style={{ marginBottom: "60px" }}></div>
            <div className="namingParagraph">
							<p>
              In the ever-evolving landscape of consumer research, a 
              fascinating experiment is set to unfold at this year's 
              SXSW conference. As the epicenter of cutting-edge conversations 
              around tech and brand innovation, SXSW provides the perfect 
              backdrop for a workshop that aims to challenge our 
              understanding of consumer research in the age of AI.
							</p>
						</div>
            <div className="namingParagraph">
              <p>
              The burning question on everyone's mind: Can AI-based research 
              replace or enhance the insights gleaned from traditional human-based 
              consumer research? <a href="https://schedule.sxsw.com/2025/events/PP155564" target="_blank" rel="noopener noreferrer">We're about to find out</a> in a live, interactive 
              showdown that pits human against machine in a 
              Jobs to Be Done-style interview.
              </p>
						</div>

            <div className="namingParagraph">
              <p>
              At the heart of this experiment lies a provocative hypothesis:
              </p>
            </div>
						<div className="namingTitle40px" style={{ marginTop: "60px", marginBottom: "60px" }}>
            <p>AI-driven consumer research might offer unique insights that complement, or 
            perhaps even surpass, traditional human-led interviews.
            </p>
						</div>
						<div className="namingParagraph">
            <p>By comparing responses from a human interviewee and a sophisticated custom AI that 
            represents the human's digital twin, we aim to uncover new perspectives on brand 
            perceptions, usage patterns, the triggers behind purchasing decisions, and the higher order benefits of products or services that consumers pull into their lives.
						</p>
            </div>
            <div style={{ width: "100%", margin: "40px 0" }}>
              <img 
                src={`${process.env.PUBLIC_URL}/images/pages/sxsw/darabot.png`}
                alt="DARA Bot"
                style={{ width: "100%", height: "auto", display: "block" }}
              />
            </div>
						<div className="namingParagraph" style={{ marginTop: "80px"}}>
							<p>This isn't just any AI we're talking about. 
                We've developed a high-tech, personality-driven LLM 
                that's quite unique. This digital twin is programmed 
                with extensive personality data from our on-stage human 
                interviewee, allowing it to respond via text and voice 
                in a manner eerily similar to its human counterpart. </p>
						</div>

            <div className="namingParagraph">
              <p>
              We'll conduct a live consumer interview in the style of a  
              Jobs to Be Done research study, asking identical questions to both 
              the human participant and the AI bot. The responses will be 
              compared in real-time, offering a unique opportunity to observe 
              how AI-generated insights stack up against human 
              experiences and perspectives.
              </p>
            </div>
            <div className="namingTitle40px" style={{ marginTop: "60px", marginBottom: "60px" }}>
            <p>But we're not stopping there.
            </p>
						</div>

						<div className="namingParagraph">
							<p>
                Workshop attendees will have the chance to actively participate 
                in the interview process, posing their own questions to both 
                the human and the AI bot. This interactive element adds an 
                unpredictable and exciting dimension to the experiment, 
                potentially uncovering insights that even we 
                haven't anticipated.
                </p>
                <p>
                As we embark on this journey, several intriguing questions come to mind:
                </p>
						</div>
            <div className="namingParagraph" style={{ marginTop: "60px", marginBottom: "60px" }}>
              <p>
              <ul className="namingQuote">
							<li>1. Will the AI bot's responses reveal unexpected consumer insights that a human might not articulate?</li>
						</ul>
            <ul className="namingQuote">
							<li>2. How will the emotional nuances and personal experiences of the human interviewee compare to the AI's data-driven responses?</li>
						</ul>
            <ul className="namingQuote">
							<li>3. Could the combination of human and AI-driven research lead to more comprehensive and accurate consumer profiles?</li>
						</ul>
            <ul className="namingQuote">
							<li>4. What ethical considerations arise when using AI for consumer research, and how might they shape the future of the industry?</li>
						</ul>
            </p>
            </div>
            <div className="namingParagraph">
              <p>
              This workshop isn't just about showcasing cool technology; 
              it's about exploring the future of consumer research and 
              the role AI might play in shaping it. Whether you're a 
              data nerd, a branding guru, a tech enthusiast, or simply 
              curious about the intersection of AI and consumer insights, 
              this workshop promises to be an eye-opening experience.
              </p>
              <p>Don't miss your chance to be part of this groundbreaking 
                experiment that could redefine how we understand and 
                approach consumer research in the AI era. The stage 
                is set, the players are ready, and the future of 
                consumer insights awaits. Will you be there to witness it?</p>
                <p style={{ textAlign: "center", margin: "40px 0" }}>
                  <div style={{ fontSize: "24px", letterSpacing: "8px", marginBottom: "20px", color: "#666" }}>• • • • •</div>
                  <a href="https://schedule.sxsw.com/2025/events/PP155564" target="_blank" rel="noopener noreferrer" style={{ fontSize: "24px" }}><b>RSVP here!</b></a>
                  <div style={{ fontSize: "24px", letterSpacing: "8px", marginTop: "20px", color: "#666" }}>• • • • •</div>
                </p>
            </div>
            <div style={{ marginBottom: "10px" }}>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>

              </div>
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
};
export default SXSWPlaceholder; 
import "./Hero.css";
import "swiper/css";
import React, { Suspense, useRef, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

/* PRELOADER */
import { Loader } from "../Loader/Loader";
// import {
//   BrowserView,
//   MobileView,
//   isBrowser,
//   isMobile,
// } from "react-device-detect";

// FRAMER MOTION //
import {
	motion,
	useMotionValueEvent,
	useScroll,
	useTransform,
} from "framer-motion";
import ShibaCanvas from "./Shiba";
import GuitarCanvas from "./Guitar";
import AbstractCanvas from "./Abstract";
import DiscoCanvas from "./Disco";
import QuillCanvas from "./Quill";
import { useMediaQuery } from "@react-hook/media-query";

const headerSlides = [
	{
		playerName: "Futuristic",
		playerPNG: "images/players/abstract.png",
		icon: "symbol",
		backGradient:
			"linear-gradient(116deg, rgba(164, 179, 255, 0.40) 1.25%, rgba(189, 203, 255, 0.40) 29.79%, rgba(206, 184, 255, 0.40) 71.6%, rgba(204, 167, 255, 0.40) 100%)",
		playerShadow:
			"radial-gradient(ellipse at 50% 50%,rgba(128, 158, 255,.8),transparent,transparent)",
		text: "/Prompt: <b>Futuristic</b>",
	},
	{
		playerName: "Shiba",
		playerPNG: "images/players/shiba.png",
		icon: "dog",
		backGradient:
			"linear-gradient(116deg,#ffde9f 1.25%,#fff2d0 29.54%,#ffe3cf 65.02%,#ffb7a0 100%)",
		playerShadow:
			"radial-gradient(ellipse at 50% 50%,rgba(253, 153, 153, .8),transparent,transparent)",
		text: "/Prompt: <b>Dog Mode</b>",
	},
	{
		playerName: "Quill",
		playerPNG: "images/players/quill.png",
		icon: "quill",
		backGradient:
			"linear-gradient(116deg, #DDD3F1 1.25%, #EEEDFF 31.59%, #FFDBE1 71.71%, #FBB 100%)",
		playerShadow:
			"radial-gradient(ellipse at 50% 50%,rgba(204, 177, 232, .8),transparent,transparent)",
		text: "/Prompt: <b>Shakespearinator</b>",
	},
	{
		playerName: "Guitar",
		playerPNG: "images/players/guitar.png",
		icon: "guitar",
		backGradient:
			"linear-gradient(116deg, #FFF2D0 1.25%, #F1FFD4 33.14%, #E1FFE6 65.02%, #D4FFF5 100%)",
		playerShadow:
			"radial-gradient(ellipse at 50% 50%,rgba(123, 149, 217, .8),transparent,transparent)",
		text: "/Prompt: <b>Heavy Metal-ic</b>",
	},
	{
		playerName: "Disco",
		playerPNG: "images/players/disco.png",
		icon: "sphere",
		backGradient:
			"linear-gradient(116deg, rgba(129, 218, 202, 0.40) 1.25%, rgba(118, 228, 202, 0.40) 29.02%, rgba(149, 236, 255, 0.40) 67.6%, rgba(149, 147, 255, 0.40) 100%)",
		playerShadow:
			"radial-gradient(ellipse at 50% 50%,rgba(190, 152, 238, .8),transparent,transparent)",
		text: "/Prompt: <b>Gen-Zify</b>",
	},
];

function Hero({
	handlePlayerChange,
	player,
	initial,
	fromBottom,
	resetFromBottom,
	textures,
	progress,
	loaded,
}) {
	/////////////
	// PLAYERS //
	/////////////

	const nextPlayer = () => {
		if (canChangeSlide) {
			if (playerSelected === 4) {
				SetPlayerSelected(0);
			} else {
				SetPlayerSelected(playerSelected + 1);
			}
			if (!sliderRef.current) return;
			sliderRef.current.slideNext();
		}
	};
	const prevPlayer = () => {
		if (canChangeSlide) {
			if (playerSelected === 0) {
				SetPlayerSelected(4);
			} else {
				SetPlayerSelected(playerSelected - 1);
			}
			if (!sliderRef.current) return;
			sliderRef.current.slidePrev();
		}
	};

	// FRAMER SCROLL ANIMATIONS //
	const ref = useRef(null);
	const { scrollYProgress } = useScroll({
		offset: ["0 0", "0.1 1"],
	});

	useMotionValueEvent(scrollYProgress, "change", (latest) => {
		setCurrentScrollPos(latest);
	});

	const opacityProgress = useTransform(scrollYProgress, [0, 1], [0, 1]);
	const opacityControlsProgress = useTransform(scrollYProgress, [0, 1], [1, 0]);
	const opacityControlsArrowProgress = useTransform(
		scrollYProgress,
		[0, 0.2],
		[1, 0]
	);

	const [progressEndValue, setProgressEndValue] = useState(0.45);
	const [currentScrollPos, setCurrentScrollPos] = useState(0);
	const [modelsToShow, setModelsToShow] = useState([0]);
	const [canChangeSlide, setCanChangeSlide] = useState(true);

	const is480 = useMediaQuery("only screen and (max-width: 480px)");
	const is768 = useMediaQuery("only screen and (max-width: 768px)");

	const sliderRef = useRef(null);

	useEffect(() => {
		if (is480) {
			setProgressEndValue(0.8);
			setProgressScrollValue(140);
		} else if (is768) {
			setProgressEndValue(0.8);
			setProgressScrollValue(300);
		} else {
			setProgressEndValue(0.45);
			setProgressScrollValue(140);
		}
	}, [is480, is768]);

	const [progressScrollValue, setProgressScrollValue] = useState(140);

	const scaleProgress = useTransform(
		scrollYProgress,
		[0, 1],
		[1, progressEndValue]
	);
	const scaleTopProgress = useTransform(
		scrollYProgress,
		[0, 1],
		[0, progressScrollValue]
	);
	const topProgress = useTransform(scrollYProgress, [0, 1], [0, -1000]);

	/// PRELOADER ///
	const [isLoading, setIsLoading] = useState(true);
	const [playerSelected, SetPlayerSelected] = useState(player);
	const [currentSlide, setCurrentSlide] = useState(player);

	useEffect(() => {
		handlePlayerChange(playerSelected, false);
	}, [playerSelected]); // eslint-disable-line

	useEffect(() => {
		SetPlayerSelected(player);
		if (!sliderRef.current) return;
		if (fromBottom || currentSlide === player) {
			sliderRef.current.slideToLoop(player, 0);
		}
		setCurrentSlide(player);
	}, [player, fromBottom]); // eslint-disable-line

	useEffect(() => {
		if (loaded) {
			setTimeout(() => {
				setIsLoading(false);
			}, 3000);
		}
	}, [loaded]);

	return (
		<section className="section_hero">
			{isLoading ? <Loader progress={progress} loaded={loaded} /> : null}

			<div className="header-players" ref={ref}>
				<motion.div
					className="players-slider"
					animate={{
						background: headerSlides[playerSelected].backGradient,
					}}
					transition={{ duration: 0.5 }}
				>
					<div className="players-slider_texts">
						<motion.div
							className="players-slider_title"
							style={{
								scale: scaleProgress,
								top: scaleTopProgress,
							}}
						>
							play
						</motion.div>
						<motion.div
							className="players-slider_subtitle"
							style={{ opacity: opacityProgress }}
						>
							An AI Exploration
						</motion.div>
					</div>
					<motion.div
						className="player-shadow"
						style={{ opacity: opacityControlsProgress }}
						animate={{
							background: headerSlides[playerSelected].playerShadow,
						}}
						transition={{ duration: 0.5 }}
					></motion.div>

					{is480 && (
						<div
							style={{
								width: "100%",
								height: "100%",
								position: "absolute",
								top: topProgress,
								zIndex: 999,
							}}
						/>
					)}

					<motion.div
						className="spline_container"
						style={{
							width: "100%",
							height: "100%",
							position: "absolute",
							top: topProgress,
							opacity: opacityControlsProgress,
							zIndex: 99,
						}}
					>
						<Swiper
							style={{ height: "100%" }}
							speed={500}
							spaceBetween={0}
							slidesPerView={1}
							allowTouchMove={false}
							simulateTouch={false}
							noSwiping
							loop
							onBeforeInit={(swiper) => {
								sliderRef.current = swiper;
							}}
							onSlideChangeTransitionStart={(swiper) => {
								setCanChangeSlide(false);
								const { realIndex } = swiper;
								setModelsToShow((oldArray) => [...oldArray, realIndex]);
							}}
							onSlideChangeTransitionEnd={(swiper) => {
								setCanChangeSlide(true);
								const { realIndex } = swiper;
								setModelsToShow([realIndex]);
							}}
						>
							<>
								<SwiperSlide>
									<Suspense fallback="Loading...">
										<AbstractCanvas
											is480={is480}
											textures={textures}
											active={
												modelsToShow.includes(0) &&
												currentScrollPos < progressEndValue
											}
										/>
									</Suspense>
								</SwiperSlide>
								<SwiperSlide>
									<Suspense fallback="Loading...">
										<ShibaCanvas
											is480={is480}
											active={
												modelsToShow.includes(1) &&
												currentScrollPos < progressEndValue
											}
										/>
									</Suspense>
								</SwiperSlide>
								<SwiperSlide>
									<Suspense fallback="Loading...">
										<QuillCanvas
											is480={is480}
											active={
												modelsToShow.includes(2) &&
												currentScrollPos < progressEndValue
											}
										/>
									</Suspense>
								</SwiperSlide>
								<SwiperSlide>
									<Suspense fallback="Loading...">
										<GuitarCanvas
											is480={is480}
											active={
												modelsToShow.includes(3) &&
												currentScrollPos < progressEndValue
											}
										/>
									</Suspense>
								</SwiperSlide>
								<SwiperSlide>
									<Suspense fallback="Loading...">
										<DiscoCanvas
											is480={is480}
											textures={textures}
											active={
												modelsToShow.includes(4) &&
												currentScrollPos < progressEndValue
											}
										/>
									</Suspense>
								</SwiperSlide>
							</>
						</Swiper>
					</motion.div>

					{/* PROMT */}
					<motion.div
						className="navigation"
						style={{ opacity: opacityControlsProgress }}
					>
						<h2 className="promt-title">
							Change your experience with each prompt{" "}
						</h2>
						<p
							className="prompt"
							dangerouslySetInnerHTML={{
								__html: headerSlides[playerSelected].text,
							}}
						></p>
					</motion.div>

					{/*// NAVIGATION //*/}
					<motion.div
						className="swiper-button image-swiper-button-next"
						style={{
							opacity: opacityControlsArrowProgress,
						}}
						onClick={() => {
							nextPlayer();
						}}
					/>
					<motion.div
						className="swiper-button image-swiper-button-prev"
						style={{
							opacity: opacityControlsArrowProgress,
						}}
						onClick={() => {
							prevPlayer();
						}}
					/>
				</motion.div>
			</div>
		</section>
	);
}

export default Hero;
